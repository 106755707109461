import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";

import { Link, useNavigate } from "react-router-dom";
import { remove } from "../../api_service";
import { TC } from "../tokens/Tokens";
import { get, post } from "../../api_service";
import Pagination from "../../components/Pagination";
import DeleteDialog from "../../components/DeleteDialog";
import SearchBar from "../../components/SearchBar";
import { toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataRow = ({ item, removeByAddress }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteItem = async () => {
    try {
      setLoading(true);
      const res = await remove(`router/${item._id}/delete`);
      if (res.status) {
        removeByAddress(item.address);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteItem}
      />
      <TableRow>
        <TC value={`${item.name} (${item.interactions})`} />
        <TC value={item.address} />
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/routers/edit", { state: item })}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDeleteOpen(true)}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            {loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ px: 1.5, py: 0.2 }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const DataTable = ({ data, removeByAddress }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Address
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow
            item={item}
            removeByAddress={removeByAddress}
            key={item._id}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const Routers = () => {
  const [routers, setRouters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchRouters = async () => {
    setLoading(true);
    const body = {
      name: searchTerm,
    };

    try {
      const res = await post(`router/search/`, body);
      setRouters(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const removeByAddress = (address) => {
    setRouters(routers.filter((router) => router.address !== address));
  };

  const loadData = async (page = 1) => {
    try {
      const res = await get(`routers?page=${page}`);
      setRouters(res.data);
      setTotalPage(res.totalPages);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handlePageClick = (e) => {
    loadData(e.selected + 1);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Available DEXs</Typography>
            <SearchBar
              searchTerm={searchTerm}
              handleChange={handleChange}
              searchFunction={searchRouters}
            />
            <Box>
              <Link to="/routers/add" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Add
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : routers.length === 0 ? (
              <Typography variant="body1">No routers found</Typography>
            ) : (
              <>
                <DataTable data={routers} removeByAddress={removeByAddress} />
                <Pagination
                  totalPage={totalPage}
                  handlePageClick={handlePageClick}
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Routers;
