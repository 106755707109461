import { InputAdornment, TextField, Box } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

const SearchBar = ({ searchTerm, handleChange, searchFunction }) => {
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      const timeoutId = setTimeout(() => {
        searchFunction();
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        flexGrow: 1,
        mb: "8px",
        mr: "8px",
      }}
    >
      <TextField
        id="search"
        size="small"
        type="search"
        value={searchTerm}
        placeholder="Search"
        onChange={(e) => {
          setChanged(true);
          handleChange(e);
        }}
        sx={{
          minWidth: { xs: 200, md: 400 },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: 6,
            },
          },
        }}
        InputProps={{
          style: {
            padding: 4,
            paddingRight: 10,
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ cursor: "pointer" }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
