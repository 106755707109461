import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Box,
  Typography,
  TableCell,
  TableRow,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";

import { TC } from "../tokens/Tokens";
import { get } from "../../api_service";
import Pagination from "../../components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataRow = ({ item }) => {
  return (
    <TableRow>
      <TC value={item.method} />
      <TC value={item.router} />
      <TC value={item.txHash} />
      <TC value={item.blockNumber} />
      <TC value={item.date} />
    </TableRow>
  );
};

const DataTable = ({ data }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Method
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Router
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Tx Hash
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Block Number
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Date
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow item={item} key={item._id} />
        ))}
      </TableBody>
    </Table>
  );
};

const ParseError = () => {
  const [addressTags, setAddressTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);

  const loadData = async (page = 1) => {
    try {
      const res = await get(`parseerrors?page=${page}`);
      setAddressTags(res.data);
      setTotalPage(res.totalPages);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handlePageClick = (e) => {
    loadData(e.selected + 1);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Parse Errors</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : addressTags.length === 0 ? (
              <Typography variant="body1">No erros found</Typography>
            ) : (
              <>
                <DataTable data={addressTags} />
                <Pagination
                  handlePageClick={handlePageClick}
                  totalPage={totalPage}
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default ParseError;
