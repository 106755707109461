import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TC } from "../tokens/Tokens";
import { get } from "../../api_service";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const PopularTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Address
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Subscription Count
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.address}>
            <TC value={item.address} />
            <TC value={item.count} />
            <TableCell align="right">
              <Button
                onClick={() => navigate("/whales/details", { state: item })}
                variant="contained"
                color="primary"
              >
                Details
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const PopularWhales = () => {
  const [popularLoading, setPopularLoading] = useState(false);
  const [popular, setPopular] = useState([]);
  const loadPopularWhales = async () => {
    setPopular([]);
    try {
      setPopularLoading(true);
      const res = await get("whales/popular");
      setPopular(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setPopularLoading(false);
  };

  useEffect(() => {
    loadPopularWhales();
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          sx={{
            justifyContent: "space-between",
            display: {
              xs: "inline",
              sm: "flex",
              lg: "flex",
            },
          }}
        >
          <Typography variant="h3">Popular Whales</Typography>
        </Box>
        <Box
          sx={{
            overflow: {
              xs: "auto",
              sm: "unset",
            },
          }}
        >
          {popularLoading ? (
            <Box sx={{ textAlign: "center", p: 5 }}>
              <CircularProgress />
            </Box>
          ) : popular.length === 0 ? (
            <Box sx={{ textAlign: "center", p: 5 }}>
              {" "}
              <Typography variant="body1">No whales found</Typography>
            </Box>
          ) : (
            <PopularTable data={popular} />
          )}
        </Box>
      </CardContent>
      <ToastContainer />
    </Card>
  );
};
export default PopularWhales;
