import React from "react";
import ReactPaginate from "react-paginate";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Box } from "@material-ui/core";
import "../assets/pagination.css";

const Pagination = ({ totalPage, handlePageClick }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
    >
      <ReactPaginate
        activeClassName={"item active "}
        breakClassName={"item break-me "}
        breakLabel={"---"}
        containerClassName={"pagination"}
        disabledClassName={"disabled-page"}
        marginPagesDisplayed={2}
        nextClassName={"item next"}
        nextLabel={<ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />}
        onPageChange={handlePageClick}
        pageCount={totalPage}
        pageClassName={"item pagination-page "}
        pageRangeDisplayed={5}
        previousClassName={"item previous"}
        previousLabel={
          <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
        }
      />
    </Box>
  );
};

export default Pagination;
