import TokenIcon from "@mui/icons-material/Token";
import RouterIcon from "@mui/icons-material/Router";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { LeakAdd } from "@material-ui/icons";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ErrorIcon from "@mui/icons-material/Error";
import KeyIcon from "@mui/icons-material/Key";

const Menuitems = [
  {
    title: "Global Tokens",
    icon: DonutSmallIcon,
    href: "/global-tokens",
  },
  {
    title: "Tokens",
    icon: TokenIcon,
    href: "/tokens",
  },
  {
    title: "DEXs",
    icon: RouterIcon,
    href: "/routers",
  },
  {
    title: "ABIs",
    icon: KeyIcon,
    href: "/abis",
  },
  {
    title: "Labels",
    icon: LocalOfferIcon,
    href: "/address-tags",
  },
  {
    title: "Providers",
    icon: LeakAdd,
    href: "/providers",
  },
  {
    title: "DEX Functions",
    icon: GroupWorkIcon,
    href: "/router-groups",
  },
  {
    title: "Notifications",
    icon: CircleNotificationsIcon,
    href: "/notifications",
  },
  {
    title: "Whales",
    icon: WhatshotIcon,
    href: "/whales",
  },
  {
    title: "Parse Errors",
    icon: ErrorIcon,
    href: "/parse-errors",
  },
];

export default Menuitems;
