import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";
import TopicEdit from "./TopicEdit";
import Topic from "./Topic";

const RouterMethodEdit = ({
  method,
  index,
  selected,
  updateSelected,
  options,
  methodFunctions,
  setMethodFunctions,
  abi,
  availableTopics,
}) => {
  const actions = ["map", "decode"];
  const [selectedAction, setSelectedAction] = useState(
    method.action || actions[0]
  );
  const [topics, setTopics] = useState(method.mappings || []);
  const [selectedMessage, setSelectedMessage] = useState("");

  useEffect(() => {
    if (selected[index] && selected[index] != 0) {
      setSelectedMessage(getSelectedMessage(selected[index]));
      // setAbiInputs(getAbiInputs(method.logAbi));
    }
  }, []);

  const addTopic = () => {
    setTopics((pre) => [...pre, { topic: "", mapping: [] }]);
  };

  const onActionChange = (e) => {
    updateSelected(e.target.value, index);
    if (e.target.value !== "0") {
      const selected = options.find((x) => x._id === e.target.value);
      setSelectedMessage(selected.message.replace(/  |\r\n|\n|\r/gm, ""));
    }
  };

  const getSelectedMessage = (id) => {
    const selected = options.find((x) => x._id === id);
    return selected.message.replace(/  |\r\n|\n|\r/gm, "");
  };

  const save = () => {
    const newMethodFunctions = methodFunctions.slice();
    const methodFunction = {
      action: selectedAction,
      mappings: topics,
    };
    newMethodFunctions[index] = methodFunction;
    setMethodFunctions(newMethodFunctions);
    toast.success("Function Mappings saved ", ToastOptions);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#eeeeee",
        my: 1,
        py: 1,
        pr: 1,
        pl: 2,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          {method.name} ({method.count})
        </Typography>
        <Select value={selected[index]} onChange={onActionChange}>
          <MenuItem value="0">
            <em>None</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option._id}>
              {option.type}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selected[index] !== "0" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button variant="contained" color="success" onClick={addTopic}>
              Add Topic
            </Button>
          </Box>
          {topics.map((topic, idx) =>
            topic.topic !== "" ? (
              <TopicEdit
                topic={topic}
                index={idx}
                setTopics={setTopics}
                topics={topics}
                key={idx}
                availableTopics={availableTopics}
                method={method}
                selectedMessage={selectedMessage}
                abi={abi}
                methodIndex={index}
              />
            ) : (
              <Topic
                index={idx}
                setTopics={setTopics}
                topics={topics}
                key={idx}
                availableTopics={availableTopics}
                method={method}
                selectedMessage={selectedMessage}
                abi={abi}
                methodIndex={index}
              />
            )
          )}
          <Typography variant="caption">Action</Typography>
          <TextField
            fullWidth
            select
            value={selectedAction}
            onChange={(e) => {
              setSelectedAction(e.target.value);
            }}
          >
            {actions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Button sx={{ mt: 2 }} variant="contained" onClick={save}>
            Save
          </Button>
        </Box>
      )}
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default RouterMethodEdit;
