import React, { useState, useEffect } from "react";
import Pagination from "../../components/Pagination";
import { globalUrl, get, post, remove } from "../../api_service";
import { TC } from "./Tokens";
import {
  Card,
  CardContent,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
  Button,
} from "@material-ui/core";
import DeleteDialog from "../../components/DeleteDialog";
import SearchBar from "../../components/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataTable = ({ data }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Symbol
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Available Networks
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((token) => (
          <DataRow token={token} key={token._id} />
        ))}
      </TableBody>
    </Table>
  );
};

const DataRow = ({ token }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(token.disabled);
  const [deleting, setDeleting] = useState(false);
  const updateStatus = async () => {
    setUpdating(true);
    const accessToken = localStorage.getItem("token");
    try {
      const res = await post(
        `token/${token._id}/update-status`,
        {
          status: !disabled,
          accessToken: accessToken,
        },
        "application/json",
        globalUrl
      );
      setDisabled(res.data.disabled);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setUpdating(false);
  };

  const deleteToken = async () => {
    setDeleting(true);
    try {
      const res = await remove(`token/${token._id}/delete`, globalUrl);
      if (res.status) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setDeleting(false);
  };

  return (
    <TableRow>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteToken}
      />
      <TC value={token.name} />
      <TC value={token.symbol} />
      <TC value={token.addresses?.length} />
      <TableCell align="right">
        <Button
          disabled={token.address === "0x"}
          variant="contained"
          color={disabled ? "primary" : "secondary"}
          onClick={() => updateStatus()}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {updating ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ px: 1.5, py: 0.2 }}
            />
          ) : disabled ? (
            "Enable"
          ) : (
            "Disable"
          )}
        </Button>
        <Button
          disabled={token.address === "0x"}
          variant="contained"
          color="error"
          onClick={() => setDeleteOpen(true)}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {deleting ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ px: 1.5, py: 0.2 }}
            />
          ) : (
            "Delete"
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const GlobalTokens = () => {
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchGlobalTokens = async () => {
    setLoading(true);
    const body = {
      name: searchTerm,
    };

    try {
      const res = await post(`token/search/`, body);
      setTokens(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const loadTokens = async (page = 1) => {
    try {
      const res = await get(
        `tokens/paginate?page=${page}&active=${false}`,
        globalUrl
      );
      setTokens(res.data);
      setTotalPage(res.totalPages);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handlePageClick = (e) => {
    loadTokens(e.selected + 1);
  };

  useEffect(() => {
    loadTokens();
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            display: {
              xs: "inline",
              sm: "flex",
              lg: "flex",
            },
          }}
        >
          <Typography variant="h3">Global Tokens</Typography>
          <SearchBar
            searchTerm={searchTerm}
            handleChange={handleChange}
            searchFunction={searchGlobalTokens}
          />
        </Box>
        <Box
          sx={{
            overflow: {
              xs: "auto",
              sm: "unset",
            },
          }}
        >
          {loading ? (
            <Box sx={{ textAlign: "center", p: 5 }}>
              <CircularProgress />
            </Box>
          ) : tokens.length === 0 ? (
            <Typography variant="body1">No tokens found</Typography>
          ) : (
            <>
              <Box sx={{ minHeight: "60vh" }}>
                <DataTable data={tokens} />
              </Box>
              <Pagination
                handlePageClick={handlePageClick}
                totalPage={totalPage}
              />
              {/* <Box>
                {isSearch === true ? (
                  <></>
                ) : (
                  <Pagination
                    handlePageClick={handlePageClick}
                    totalPage={totalPage}
                  />
                )}
              </Box> */}
            </>
          )}
        </Box>
      </CardContent>
      <ToastContainer />
    </Card>
  );
};

export default GlobalTokens;
