import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { get, post } from "../../api_service";
import { ethers } from "ethers";
import { useNavigate, useLocation } from "react-router-dom";
import RouterMethodEdit from "./RouterMethodEdit";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";

const EditRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [data, setData] = useState({
    name: location.state.name,
    address: location.state.address,
  });
  const [showError, setShowError] = useState(false);
  const [selected, setSelected] = useState([]);
  const [methodFunctions, setMethodFunctions] = useState([]);
  const [availableTopics, setAvailableTopics] = useState([])

  useEffect(() => {
    let functions = [];
    location.state.functions.forEach((f) => {
      functions.push({
        name: f.name,
        action: f.action,
        mappings: f.mappings,
      });
    });
    setMethodFunctions(functions);
    loadAvailableTopics()
  }, []);

  const isValid = () => {
    return ethers.utils.isAddress(data.address) && data.name !== "";
  };
  const update = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        let functions = [];
        selected.forEach((item, index) => {
          const option = options.find((e) => e._id === item);
          const methodFunction = methodFunctions[index];
          functions.push({
            name: location.state.functions[index].name,
            mappings: methodFunction.mappings,
            gid: option ? option._id : 0,
          });
        });
        const body = {
          name: data.name,
          address: data.address,
          functions: functions,
        };
        const res = await post(
          `router/${location.state._id}/update`,
          body,
          "application/json"
        );
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.response?.data?.message || e.message, ToastOptions);
    }
  };

  const loadOptions = async () => {
    setOptionsLoading(true);
    try {
      const res = await get("router-groups");
      setOptions(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setOptionsLoading(false);
  };

  const loadSelectedOptions = useCallback(() => {
    let selectedOptions = [];
    location.state.functions.forEach((e) => {
      selectedOptions.push(e.gid);
    });
    setSelected(selectedOptions);
  }, [location.state.functions]);

  const loadAvailableTopics = async() => {
    try {
      const res = await get(`/abis/all`);
      setAvailableTopics(res.data);
    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    loadSelectedOptions();
    loadOptions();
  }, [loadSelectedOptions]);

  const updateSelected = (value, index) => {
    const updated = [
      ...selected.slice(0, index),
      value,
      ...selected.slice(index + 1),
    ];
    setSelected(updated);
  };

  const getIndex = (method) => {
    return methodFunctions.findIndex((e) => e.name === method.name);
  };

  // console.log(location.state.functions);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Edit DEX</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {optionsLoading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <form>
                <TextField
                  id="default-value"
                  label="Address"
                  variant="outlined"
                  value={data.address}
                  error={showError && !ethers.utils.isAddress(data.address)}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  helperText={
                    showError && !ethers.utils.isAddress(data.address)
                      ? "Address is not valid"
                      : ""
                  }
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                />
                <TextField
                  value={data.name}
                  id="default-value"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={showError && data.name === ""}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  helperText={showError && data.name === "" ? errorTxt : ""}
                  sx={{
                    mt: 2,
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h2">
                    Available Methods in router
                  </Typography>
                  {location.state.functions.length === 0 ? (
                    <Typography variant="body1">No methods found.</Typography>
                  ) : (
                    location.state.functions
                      .slice()
                      // .sort((a, b) => console.log(b.count, a.count))
                      .sort((a, b) => b.count - a.count)
                      .map((method, index) => {
                        // console.log(method);
                        return (
                          <RouterMethodEdit
                            method={method}
                            key={index}
                            index={location.state.functions.indexOf(method)}
                            selected={selected}
                            updateSelected={updateSelected}
                            options={options}
                            methodFunctions={methodFunctions}
                            setMethodFunctions={setMethodFunctions}
                            abi={location.state.abi}
                            availableTopics={availableTopics}
                          />
                        );
                      })
                  )}
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    textAlign: "right",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    size="large"
                    onClick={() => update()}
                  >
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={25}
                        sx={{ px: 2 }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default EditRouter;
