import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post } from "../../api_service";
import { ethers } from "ethers";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";

const EditAddressTag = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: location.state.name,
    address: location.state.address,
  });
  const [showError, setShowError] = useState(false);
  const isValid = () => {
    return ethers.utils.isAddress(data.address) && data.name !== "";
  };
  const update = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        const res = await post(`adresstag/${location.state._id}/update`, data);
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Edit Label</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                id="default-value"
                label="Address"
                variant="outlined"
                value={data.address}
                error={showError && !ethers.utils.isAddress(data.address)}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                helperText={
                  showError && !ethers.utils.isAddress(data.address)
                    ? "Address is not valid"
                    : ""
                }
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                value={data.name}
                id="default-value"
                label="Name"
                variant="outlined"
                fullWidth
                error={showError && data.name === ""}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                helperText={showError && data.name === "" ? errorTxt : ""}
                sx={{
                  mt: 2,
                }}
              />

              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => update()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default EditAddressTag;
