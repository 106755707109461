import React, { useEffect, useState } from "react";
import { remove } from "../../api_service";
import { TC } from "../tokens/Tokens";
import { get } from "../../api_service";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteDialog from "../../components/DeleteDialog";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataRow = ({ item, removeByAddress }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const deleteItem = async () => {
    try {
      setLoading(true);
      const res = await remove(`provider/${item._id}/delete`);
      if (res.status) {
        removeByAddress(item.link);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <TableRow>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteItem}
      />
      <TC value={item.provider} />
      <TC value={item.link} />
      <TableCell align="right">
        <Button
          variant="contained"
          color="error"
          onClick={() => setDeleteOpen(true)}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {loading ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ px: 1.5, py: 0.2 }}
            />
          ) : (
            "Delete"
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ data, removeByAddress }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Provider
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Link
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow
            item={item}
            removeByAddress={removeByAddress}
            key={item._id}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  const removeByAddress = (link) => {
    setProviders(providers.filter((provider) => provider.link !== link));
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await get("providers");
      setProviders(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Available Providers</Typography>
            <Box>
              <Link to="/providers/add" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Add
                </Button>
              </Link>
              <Button
                variant="contained"
                color="success"
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                Bulk Add
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : providers.length === 0 ? (
              <Typography variant="body1">No providers found</Typography>
            ) : (
              <DataTable data={providers} removeByAddress={removeByAddress} />
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default Providers;
