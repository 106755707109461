import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post, get } from "../../api_service";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RouterMethod from "./RouterMethod";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";
import "react-toastify/dist/ReactToastify.min.css";

const errorTxt = "This field is required";
const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;

const AddRouter = () => {
  const navigate = useNavigate();
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [methodsLoaded, setMethodsLoaded] = useState(false);
  const [methods, setMethods] = useState([]);
  const [options, setOptions] = useState([]);
  const [methodFunctions, setMethodFunctions] = useState([]);
  const [data, setData] = useState({
    name: "",
    address: "",
    abi: "",
  });
  const [selected, setSelected] = useState([]);
  const [showError, setShowError] = useState(false);
  const [availableTopics, setAvailableTopics] = useState([])
  const isValid = () => {
    return ethers.utils.isAddress(data.address) && data.name !== "";
  };

  const add = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        let functions = [];
        selected.forEach((item, index) => {
          const option = options.find((e) => e._id === item);
          const methodFunction = methodFunctions[index];
          functions.push({
            name: methods[index].name,
            action: methodFunction.action,
            mappings: methodFunction.mappings,
            gid: option ? option._id : 0,
          });
        });
        const body = {
          name: data.name,
          address: data.address,
          abi: data.abi,
          functions: functions,
        };
        const res = await post("router/add", body, "application/json");
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.response?.data?.message || e.message, ToastOptions);
    }
  };

  const onAddressChange = async (address) => {
    setLoading(true);
    try {
      if (ethers.utils.isAddress(address)) {
        const res = await axios.get(
          `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`
        );
        if (res.data.result) {
          const abiString = res.data.result;
          const abi = JSON.parse(abiString);
          let abiFunctions = [];
          let selectedOptions = [];
          let functions = [];
          abi.forEach((e) => {
            if (e.type === "function") {
              abiFunctions.push(e);
              selectedOptions.push("0");
              functions.push({
                action: "",
                logAbi: "",
                mapping: [],
              });
            }
          });
          setMethodFunctions(functions);
          setSelected(selectedOptions);
          setMethods(abiFunctions);
          setMethodsLoaded(true);
          setData({ ...data, abi: abiString, address: address });
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const updateSelected = (value, index) => {
    const updated = [
      ...selected.slice(0, index),
      value,
      ...selected.slice(index + 1),
    ];
    setSelected(updated);
  };

  const loadOptions = async () => {
    setOptionsLoading(true);
    try {
      const res = await get("router-groups");
      setOptions(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setOptionsLoading(false);
  };

  const loadAvailableTopics = async() => {
    try {
      const res = await get(`/abis/all`);
      setAvailableTopics(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    loadOptions();
    loadAvailableTopics();
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add DEX</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {optionsLoading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <form>
                <TextField
                  id="default-value"
                  label="Address"
                  variant="outlined"
                  error={showError && !ethers.utils.isAddress(data.address)}
                  onChange={(e) => onAddressChange(e.target.value)}
                  helperText={
                    showError && !ethers.utils.isAddress(data.address)
                      ? "Address is not valid"
                      : ""
                  }
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                />
                <TextField
                  value={data.name}
                  id="default-value"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={showError && data.name === ""}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  helperText={showError && data.name === "" ? errorTxt : ""}
                  sx={{
                    mt: 2,
                  }}
                />
                {methodsLoaded && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h2">
                      Available Methods in router
                    </Typography>
                    {methods.length === 0 ? (
                      <Typography variant="body1">No methods found.</Typography>
                    ) : (
                      methods.map((method, index) => (
                        <RouterMethod
                          key={index}
                          index={index}
                          method={method}
                          selected={selected}
                          updateSelected={updateSelected}
                          options={options}
                          methodFunctions={methodFunctions}
                          setMethodFunctions={setMethodFunctions}
                          availableTopics={availableTopics}
                          abi={data.abi}
                        />
                      ))
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    mt: 2,
                    textAlign: "right",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    size="large"
                    onClick={() => add()}
                  >
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={25}
                        sx={{ px: 2 }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddRouter;
