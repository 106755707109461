import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post } from "../../api_service";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";

const AddProvider = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    provider: "",
    link: "",
  });
  const [showError, setShowError] = useState(false);
  const isValid = () => {
    return data.provider !== "" && data.link !== "";
  };
  const add = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        const res = await post("provider/add", data);
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add Provider</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                id="default-value"
                label="Provider"
                variant="outlined"
                error={showError && data.provider === ""}
                onChange={(e) => setData({ ...data, provider: e.target.value })}
                helperText={showError && data.provider === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                value={data.name}
                id="default-value"
                label="Link"
                variant="outlined"
                fullWidth
                error={showError && data.link === ""}
                onChange={(e) => setData({ ...data, link: e.target.value })}
                helperText={showError && data.link === "" ? errorTxt : ""}
                sx={{
                  mt: 2,
                }}
              />

              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => add()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddProvider;
