import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { get, post } from "../../api_service";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@material-ui/core/Autocomplete";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";

const AddNotification = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [showError, setShowError] = useState(false);
  const isValid = () => {
    return name !== "" && selectedMethods.length > 0;
  };
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [methods, setMethods] = useState([]);

  const getMethods = async () => {
    setDataLoading(true);
    try {
      const res = await get("router-groups/unused");
      setMethods(res.data);
      console.log(res.data);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    getMethods();
  }, []);

  const add = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        const fids = [];
        selectedMethods.forEach((method) => {
          fids.push(method._id);
        });
        const data = {
          name: name,
          fids: fids,
        };
        const res = await post("notifications/add", data, "application/json");
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add Notification Group</Typography>
          {dataLoading ? (
            <Box sx={{ textAlign: "center", p: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <form>
                <TextField
                  value={name}
                  id="default-value"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={showError && name === ""}
                  onChange={(e) => setName(e.target.value)}
                  helperText={showError && name === "" ? errorTxt : ""}
                  sx={{
                    mt: 2,
                  }}
                />
                <Autocomplete
                  sx={{
                    mt: 2,
                  }}
                  multiple
                  options={methods}
                  getOptionLabel={(option) => option?.type}
                  onChange={(e, value) => setSelectedMethods(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={showError && selectedMethods.length === 0}
                      helperText={
                        showError && selectedMethods.length === 0
                          ? "Please select at least one type"
                          : ""
                      }
                      label="Message Types"
                      placeholder="Message Types"
                    />
                  )}
                />
                <Box
                  sx={{
                    mt: 2,
                    textAlign: "right",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    size="large"
                    onClick={() => add()}
                  >
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={25}
                        sx={{ px: 2 }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddNotification;
