import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../api_service";

const EditABIs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [topic, setTopic] = useState(location.state.topic);
  const [abi, setAbi] = useState(location.state.abi);
  const [showError, setShowError] = useState(false);
  const isValid = () => {
    return topic !== "" && abi !== "";
  };

  const update = async () => {
    setShowError(true);
    if (isValid()) {
      setLoading(true);
      try {
        const body = {
          topic: topic,
          abi: abi,
        };
        const res = await post(`abi/${location.state._id}/update`, body);
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
        toast.error(e.response?.data?.message || e.message, ToastOptions);
      }
    }
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Edit ABI</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                value={topic}
                id="default-value"
                label="Topic"
                variant="outlined"
                error={showError && topic === ""}
                onChange={(e) => setTopic(e.target.value)}
                helperText={
                  showError && topic === "" ? "This field is required" : ""
                }
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                value={abi}
                id="default-value"
                label="ABI"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setAbi(e.target.value);
                }}
                error={showError && abi === ""}
                helperText={
                  showError && abi === "" ? "This field is required" : ""
                }
                sx={{
                  mt: 2,
                }}
              />
              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => update()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default EditABIs;
