import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post, globalUrl } from "../../api_service";
import { ethers } from "ethers";
import { abi } from "../../abi";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const getProvider = () => {
  const network = JSON.parse(localStorage.getItem("network"));
  return network?.defaultProvider;
};

const errorTxt = "This field is required";
const providerUrl = getProvider();
const provider = new ethers.providers.JsonRpcProvider(providerUrl);

const AddToken = () => {
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [globalTokens, setGlobalTokens] = useState([
    { _id: 0, name: "Add as new token" },
  ]);
  const [data, setData] = useState({
    name: "",
    symbol: "",
    decimals: "",
    address: "",
    minlimit: 0,
  });
  const [selectedGlobal, setSelectedGlobal] = useState(null);
  const [showError, setShowError] = useState(false);

  const isValid = () => {
    let valid = true;
    for (const key in data) {
      if (key === "address") {
        valid = ethers.utils.isAddress(data[key]);
      } else {
        valid = data[key] !== "";
      }
    }
    return valid && selectedGlobal;
  };

  const getNetworkId = () => {
    const network = JSON.parse(localStorage.getItem("network"));
    return network.networkId;
  };

  const addToGlobal = async () => {
    if (selectedGlobal._id === 0) {
      const res = await post(
        `token/add`,
        {
          name: data.name,
          symbol: data.symbol,
          addresses: [
            {
              networkId: getNetworkId(),
              address: data.address,
            },
          ],
        },
        "application/json",
        globalUrl
      );
      if (res.status) {
        navigate(-1);
      }
    } else {
      const res = await post(
        `token/${selectedGlobal._id}/add-address`,
        {
          networkId: getNetworkId(),
          address: data.address,
        },
        "application/json",
        globalUrl
      );
      if (res.status) {
        navigate(-1);
      }
    }
  };

  const add = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        if (image) {
          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("symbol", data.symbol);
          formData.append("address", data.address);
          formData.append("decimals", data.decimals);
          formData.append("minlimit", data.minlimit);
          formData.append("image", image);
          console.log('formdata', formData)
          const res = await post("token/add", formData);
          if (res.status) {
            await addToGlobal();
          }
        } else {
          const res = await post("token/add", data);
          if (res.status) {
            await addToGlobal();
          }
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.response?.data?.message || e.message, ToastOptions);
    }
  };

  const handleAddressChange = async (address) => {
    if (ethers.utils.isAddress(address)) {
      setLoading(true);
      const contract = new ethers.Contract(address, abi, provider);
      const name = await contract.name();
      const decimals = await contract.decimals();
      const symbol = await contract.symbol();
      setData({
        name: name,
        decimals: decimals,
        symbol: symbol,
        address: address,
        minlimit: 0,
      });
      setLoading(false);
    }
  };

  const loadGlobal = async (name) => {
    try {
      const res = await post(
        `token/search`,
        {
          name: name,
        },
        "application/json",
        globalUrl
      );
      setGlobalTokens([{ _id: 0, name: "Add as new token" }, ...res.data]);
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message || e.message, ToastOptions);
    }
  };

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add Token</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                id="default-value"
                label="Address"
                variant="outlined"
                error={showError && !ethers.utils.isAddress(data.address)}
                onChange={(e) => handleAddressChange(e.target.value)}
                helperText={
                  showError && !ethers.utils.isAddress(data.address)
                    ? "Address is not valid"
                    : ""
                }
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.name}
                label="Name"
                variant="outlined"
                fullWidth
                error={showError && data.name === ""}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                helperText={showError && data.name === "" ? errorTxt : ""}
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.symbol}
                label="Symbol"
                variant="outlined"
                error={showError && data.symbol === ""}
                helperText={showError && data.symbol === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.decimals}
                label="Decimals"
                variant="outlined"
                type="number"
                error={showError && data.decimals === ""}
                helperText={showError && data.decimals === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                value={data.minlimit}
                label="Minimum Limit (USD)"
                variant="outlined"
                type="number"
                onChange={(e) => setData({ ...data, minlimit: e.target.value })}
                error={showError && data.minlimit === ""}
                helperText={showError && data.minlimit === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <Autocomplete
                disablePortal
                fullWidth
                onChange={(event, value) => {
                  setSelectedGlobal(value);
                }}
                onInputChange={(event, value) => {
                  loadGlobal(value);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                options={globalTokens}
                renderInput={(params) => (
                  <TextField
                    disabled
                    error={showError && !selectedGlobal}
                    helperText={showError && !selectedGlobal ? errorTxt : ""}
                    {...params}
                    label="Global Token"
                  />
                )}
                sx={{
                  mt: 2,
                }}
              />
              <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <input
                  type="file"
                  multiple={false}
                  accept="image/*"
                  onChange={onImageChange}
                />
                {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt={""}
                    style={{ height: "50px" }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => add()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddToken;
