import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Button, TextField } from "@material-ui/core";
import RouterParamEdit from "./RouterParamEdit";
import { toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const TopicEdit = ({
  topic,
  availableTopics,
  method,
  selectedMessage,
  setTopics,
  index,
  topics,
  abi,
  methodIndex
}) => {
  const [params, setParams] = useState(topic.mapping);
  const [abiInputs, setAbiInputs] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const getInput = () => {
    let functionIns = [];
    JSON.parse(abi).forEach((x) => {
      if (x.type === "function") {
        functionIns.push(x.inputs);
      }
    });
    return functionIns[methodIndex];
  };

  useEffect(() => {
    setSelectedValue(getSelectedTopic());
    setAbiInputs(getAbiInputs(topic.abi));
  }, []);

  const getAbiInputs = (abi) => {
    const inputs = [];
    const parsed = JSON.parse(abi);
    parsed[0].inputs.forEach((e) => {
      inputs.push(e.name);
    });
    return inputs;
  };

  const getActions = (message) => {
    const actions = [];
    const words = message.split(" ");
    words.forEach((word) => {
      if (word.startsWith("[")) {
        actions.push(
          word.substring(word.indexOf("[") + 1, word.lastIndexOf("]"))
        );
      }
    });
    return actions;
  };

  const removeAtIndex = (index) => {
    setTopics([...topics.slice(0, index), ...topics.slice(index + 1)]);
  };

  const getSelectedTopic = () => {
    return availableTopics.find((e) => e.topic === topic.topic);
  };

  const handleChange = (value) => {
    if (value) {
      setSelectedValue(value);
      setAbiInputs(getAbiInputs(value.abi));
      const params = [];
      setParams([]);
      const actions = getActions(selectedMessage);
      actions.forEach((action) => {
        params.push({
          prop: action,
          from: "",
          name: [],
          type: "",
          index: undefined,
          finalType: undefined,
          decimal: "",
        });
      });
      setParams(params);
    } else {
      setParams([]);
    }
  };

  const save = () => {
    const newTopics = topics.slice();
    const tp = {
      topic: selectedValue.topic,
      abi: selectedValue.abi,
      mapping: params,
    };
    newTopics[index] = tp;
    setTopics(newTopics);
    toast.success("Topic mapping Saved!", ToastOptions);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Autocomplete
        disablePortal
        fullWidth
        value={selectedValue || null}
        onChange={(event, value) => {
          handleChange(value);
        }}
        getOptionLabel={(option) => option.topic}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={availableTopics}
        renderInput={(params) => (
          <TextField disabled {...params} label="Select Topic" />
        )}
        sx={{
          mt: 1.5,
        }}
      />

      <Box>
        {params.map((param, idx) => (
          <RouterParamEdit
            key={idx}
            index={idx}
            param={param}
            params={params}
            setParams={setParams}
            inputs={getInput()}
            logInputs={abiInputs}
          />
        ))}
      </Box>
      
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
      <Button
          variant="contained"
          color="error"
          sx={{width: '50%'}}
          onClick={() => removeAtIndex(index)}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{width: '50%'}}
          onClick={() => save()}
        >
          Save
        </Button>
       
      </Box>
    </Box>
  );
};

export default TopicEdit;
