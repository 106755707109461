import axios from "axios";

const getBaseUrl = () => {
  const network = JSON.parse(localStorage.getItem("network"));
  return `${network?.baseUrl}/`;
};

export const setBaseUrl = (url) => {
  baseUrl = url;
};

export let baseUrl = getBaseUrl();
// export let baseUrl = "http://localhost:5000/";
export const globalUrl = `${process.env.REACT_APP_GLOBAL_API_URL}`;
const api = "api/";
const auth = "auth/";

export const get = async (url, base = baseUrl) => {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${base}${api}${url}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const post = async (
  url,
  data,
  contentType = "multipart/form-data",
  base = baseUrl
) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(`${base}${api}${url}`, data, {
    headers: {
      "Content-Type": contentType,
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const remove = async (url, base = baseUrl) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(`${base}${api}${url}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const postAuth = async (url, data) => {
  const res = await axios.post(`${globalUrl}${auth}${url}`, data);
  return res.data;
};
