import { Navigate, Outlet } from "react-router-dom";
import Tokens from "../views/tokens/Tokens";
import AddToken from "../views/tokens/AddTokens";
import EditToken from "../views/tokens/EditToken";
import Routers from "../views/routers/Routers";
import AddressTags from "../views/Labels/AddressTags";
import FullLayout from "../layouts/FullLayout/FullLayout";
import AddRouter from "../views/routers/AddRouter";
import EditRouter from "../views/routers/EditRouter";
import AddAddressTag from "../views/Labels/AddAddressTag";
import EditAddressTag from "../views/Labels/EditAddressTag";
import Login from "../views/Login/Login";
import Providers from "../views/providers/Providers";
import AddProvider from "../views/providers/AddProviders";
import RouterGroups from "../views/Router Group/RouterGroup";
import AddRouterGroup from "../views/Router Group/AddRouterGroup";
import EditRouterGroup from "../views/Router Group/EditRouterGroup";
import Notifications from "../views/Notifications/Notifications";
import AddNotification from "../views/Notifications/AddNotification";
import UpdateNotification from "../views/Notifications/UpdateNotification";
import Whales from "../views/whales/Whales";
import AddWhale from "../views/whales/AddWhale";
import EditWhale from "../views/whales/EditWhale";
import WhaleDetail from "../views/whales/WhaleDetail";
import PopularWhales from "../views/whales/PopularWhales";
import GlobalTokens from "../views/tokens/GlobalTokens";
import ParseError from "../views/ParseErrors/ParseError";
import ABIs from "../views/ABIs/ABIs";
import AddABIs from "../views/ABIs/AddABIs";
import EditABIs from "../views/ABIs/EditABIs";

/*****Routes******/
const ProtectedRoutes = (props) => {
  const auth = localStorage.getItem("token");
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const ThemeRoutes = [
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/",
        element: <FullLayout />,
        children: [
          { path: "/", element: <Navigate to="tokens" /> },
          { path: "tokens", exact: true, element: <Tokens /> },
          { path: "tokens/add", exact: true, element: <AddToken /> },
          { path: "tokens/edit", exact: true, element: <EditToken /> },
          { path: "global-tokens", exact: true, element: <GlobalTokens /> },
          { path: "routers", exact: true, element: <Routers /> },
          { path: "routers/add", exact: true, element: <AddRouter /> },
          { path: "routers/edit", exact: true, element: <EditRouter /> },
          { path: "router-groups", exact: true, element: <RouterGroups /> },
          {
            path: "router-groups/add",
            exact: true,
            element: <AddRouterGroup />,
          },
          {
            path: "router-groups/edit",
            exact: true,
            element: <EditRouterGroup />,
          },
          { path: "abis", exact: true, element: <ABIs /> },
          { path: "abis/add", exact: true, element: <AddABIs /> },
          { path: "abis/edit", exact: true, element: <EditABIs /> },
          { path: "address-tags", exact: true, element: <AddressTags /> },
          { path: "address-tags/add", exact: true, element: <AddAddressTag /> },
          {
            path: "address-tags/edit",
            exact: true,
            element: <EditAddressTag />,
          },
          { path: "providers", exact: true, element: <Providers /> },
          { path: "providers/add", exact: true, element: <AddProvider /> },
          { path: "notifications", exact: true, element: <Notifications /> },
          {
            path: "notifications/add",
            exact: true,
            element: <AddNotification />,
          },
          {
            path: "notifications/Edit",
            exact: true,
            element: <UpdateNotification />,
          },
          { path: "whales", exact: true, element: <Whales /> },
          { path: "whales/add", exact: true, element: <AddWhale /> },
          { path: "whales/edit", exact: true, element: <EditWhale /> },
          { path: "whales/details", exact: true, element: <WhaleDetail /> },
          { path: "whales/popular", exact: true, element: <PopularWhales /> },
          { path: "parse-errors", exact: true, element: <ParseError /> },
        ],
      },
    ],
  },
];

export default ThemeRoutes;
