import React from "react";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import Logout from "@material-ui/icons/Logout";
import { get, globalUrl } from "../../../api_service";
import CellTowerIcon from "@mui/icons-material/CellTower";

import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Button,
  Avatar,
  ListItemIcon,
  Typography,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [networks, setNetworks] = React.useState([]);
  const network = JSON.parse(localStorage.getItem("network"))

  const switchNetwork = (network) => {
    localStorage.setItem("network", JSON.stringify(network));
    window.location.reload();
  };

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  React.useEffect(() => {
    const loadNetworks = async () => {
      try {
        const res = await get("networks", globalUrl);
        setNetworks(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    loadNetworks();
  }, []);

  return (
    <AppBar sx={props.sx} elevation={0} className={props.customClass}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={props.toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "inline",
            },
          }}
        >
          <MenuOutlinedIcon width="20" height="20" />
        </IconButton>

        <Box flexGrow={1} />
        <Button
          aria-label="menu"
          color="inherit"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick4}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {network.name}
            </Typography>
            <Avatar
              src={
                "https://assets-global.website-files.com/5f973c970bea5548ad4287ef/61e70d05f3c7146ab79e66bb_ethereum-eth.svg"
              }
              alt="userimg"
              sx={{
                marginLeft: "10px",
                width: "30px",
                height: "30px",
              }}
            />
          </Box>
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl4}
          keepMounted
          open={Boolean(anchorEl4)}
          onClose={handleClose4}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          sx={{
            "& .MuiMenu-paper": {
              width: "250px",
              right: 0,
              top: "70px !important",
            },
          }}
        >
          {networks.map((network) => {
            return (
              <MenuItem
                key={network._id}
                onClick={() => switchNetwork(network)}
              >
                <ListItemIcon>
                  <CellTowerIcon fontSize="small" />
                </ListItemIcon>
                {network.name}
              </MenuItem>
            );
          })}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
