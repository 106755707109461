import {
  Box,
  Button,
  CircularProgress,
  experimentalStyled,
  TextField,
  Typography,
} from "@material-ui/core";
import loginImg from "../../assets/images/login.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, postAuth, globalUrl, setBaseUrl } from "../../api_service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ToastOptions from "../../components/ToastOptions";

const MainWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));

const errorTxt = "This field is required";

const Login = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const loadNetworks = async () => {
    try {
      const res = await get("networks", globalUrl);
      return res.data;
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
  };

  const onClickLogin = async () => {
    try {
      setShowError(true);
      if (data.email !== "" && data.password !== "") {
        setLoading(true);
        const res = await postAuth("login", data);
        if (res.status) {
          toast.success("Login Successfully", ToastOptions);
          const networks = await loadNetworks();
          localStorage.setItem("network", JSON.stringify(networks[0]));
          localStorage.setItem("token", res.data.access_token);
          setBaseUrl(`${networks[0]?.baseUrl}/`);
          navigate("/");
        } else {
          toast.error(res.message, ToastOptions);
        }
      }
    } catch (e) {
      toast.error(e.response?.data?.message, ToastOptions);
    }
    setLoading(false);
  };

  return (
    <MainWrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h1">Welcome Back</Typography>
            <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
              Welcome Back, please enter your details to continue.
            </Typography>
            <Typography variant="caption" sx={{ mt: 0.5, mb: 0.5 }}>
              Email Address
            </Typography>
            <TextField
              type="email"
              sx={{ mb: 2 }}
              inputProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                },
              }}
              error={showError && data.email === ""}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              helperText={showError && data.email === "" ? errorTxt : ""}
            />
            <Typography variant="caption">Password</Typography>
            <TextField
              type="password"
              sx={{ mb: 2 }}
              inputProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                },
              }}
              error={showError && data.password === ""}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              helperText={showError && data.password === "" ? errorTxt : ""}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ py: 1.3, mb: 2 }}
              onClick={() => onClickLogin()}
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} sx={{ px: 2 }} />
              ) : (
                <Typography variant="h4">Sign In</Typography>
              )}
            </Button>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              Don't have an account yet?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "50vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eeeeee",
          }}
        >
          <img alt="Login" src={loginImg} style={{ width: "80%" }} />
        </Box>
      </Box>
      <ToastContainer />
    </MainWrapper>
  );
};

export default Login;
