import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";

import { Link, useNavigate } from "react-router-dom";
import { remove } from "../../api_service";
import { TC } from "../tokens/Tokens";
import { get } from "../../api_service";
import DeleteDialog from "../../components/DeleteDialog";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataRow = ({ item, removeById }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteItem = async () => {
    try {
      setLoading(true);
      const res = await remove(`router-group/${item._id}/delete`);
      if (res.status) {
        removeById(item._id);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };
  return (
    <TableRow>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteItem}
      />
      <TC value={item.type} />
      <TC value={item.message} />
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/router-groups/edit", { state: item })}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          Edit
        </Button>
        <Button
          disabled={item.type.toLowerCase() === "transfer"}
          variant="contained"
          color="error"
          onClick={() => setDeleteOpen(true)}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {loading ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ px: 1.5, py: 0.2 }}
            />
          ) : (
            "Delete"
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ data, removeById }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell width={150}>
            <Typography color="textSecondary" variant="h6">
              Type
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Message
            </Typography>
          </TableCell>
          <TableCell align="right" width={200}>
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow item={item} removeById={removeById} key={item._id} />
        ))}
      </TableBody>
    </Table>
  );
};

const RouterGroups = () => {
  const [routerGroups, setRouterGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const removeById = (id) => {
    setRouterGroups(
      routerGroups.filter((routerGroup) => routerGroup._id !== id)
    );
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await get("router-groups");
      setRouterGroups(res.data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Available Dex Functions</Typography>
            <Box>
              <Link to="/router-groups/add" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Add
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : routerGroups.length === 0 ? (
              <Typography variant="body1">No dex functions found</Typography>
            ) : (
              <DataTable data={routerGroups} removeById={removeById} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RouterGroups;
