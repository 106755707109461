import React, { useEffect, useState, useRef } from "react";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";

import { Link, useNavigate } from "react-router-dom";
import { remove } from "../../api_service";
import { TC } from "../tokens/Tokens";
import { get, baseUrl, post } from "../../api_service";
import Pagination from "../../components/Pagination";
import DeleteDialog from "../../components/DeleteDialog";
import SearchBar from "../../components/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const DataRow = ({ item, removeByAddress }) => {
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const deleteItem = async () => {
    try {
      setLoading(true);
      const res = await remove(`addresstag/${item._id}/delete`);
      if (res.status) {
        removeByAddress(item.address);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.message, ToastOptions);
    }
  };
  return (
    <TableRow>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteItem}
      />
      <TC value={item.name} />
      <TC value={item.address} />
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/address-tags/edit", { state: item })}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setDeleteOpen(true)}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {loading ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ px: 1.5, py: 0.2 }}
            />
          ) : (
            "Delete"
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ data, removeByAddress }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Address
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow
            item={item}
            removeByAddress={removeByAddress}
            key={item._id}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const AddressTags = () => {
  const [addressTags, setAddressTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bulkUpload, setBulkUpload] = useState(false);
  const inputRef = useRef(null);
  const [totalPage, setTotalPage] = useState(0);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      await uploadFile(fileObj);
    }
  };

  const uploadFile = async (fileObj) => {
    setBulkUpload(true);
    try {
      const data = new FormData();
      data.append("file", fileObj);
      const res = await post("addresstag/fromfile", data);
      if (res.status) {
        window.location.reload();
      }
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setBulkUpload(false);
  };

  const removeByAddress = (address) => {
    setAddressTags(addressTags.filter((router) => router.address !== address));
  };

  const loadData = async (page = 1) => {
    try {
      const res = await get(`addresstags?page=${page}`);
      setAddressTags(res.data);
      setTotalPage(res.totalPages);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handlePageClick = (e) => {
    loadData(e.selected + 1);
  };

  useEffect(() => {
    loadData();
  }, []);

  const downloadTemplate = () => {
    window.location.href = `${baseUrl}assets/address_tag_template.csv`;
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchLabels = async () => {
    setLoading(true);
    const body = {
      name: searchTerm,
    };

    try {
      const res = await post(`addressTag/search/`, body);
      setAddressTags(res.data);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  return (
    <Box>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        multiple={false}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Available Labels</Typography>
            <SearchBar
              searchTerm={searchTerm}
              handleChange={handleChange}
              searchFunction={searchLabels}
            />
            <Box>
              <Link to="/address-tags/add" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Add
                </Button>
              </Link>
              <Button
                variant="contained"
                color="success"
                onClick={() => inputRef.current.click()}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                {bulkUpload ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ px: 1.5, py: 0.2 }}
                  />
                ) : (
                  "Bulk Upload"
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => downloadTemplate()}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                Template
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : addressTags.length === 0 ? (
              <Typography variant="body1">No address tags found</Typography>
            ) : (
              <>
                <DataTable
                  data={addressTags}
                  removeByAddress={removeByAddress}
                />
                <Pagination
                  handlePageClick={handlePageClick}
                  totalPage={totalPage}
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddressTags;
