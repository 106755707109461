import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import SearchBar from "../../components/SearchBar";
import { get, post, remove } from "../../api_service";
import { ToastContainer, toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";
import DeleteDialog from "../../components/DeleteDialog";
import { TC } from "../tokens/Tokens";
import Pagination from "../../components/Pagination";
import { Link, useNavigate } from "react-router-dom";

const DataRow = ({ item, removeByAbi }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteItem = async () => {
    try {
      setLoading(true);
      const res = await remove(`abi/${item._id}/delete`);
      if (res.status) {
        removeByAbi(item.abi);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteItem}
      />
      <TableRow>
        <TC value={item.topic} />
        <TC value={item.abi} />
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/abis/edit", { state: item })}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDeleteOpen(true)}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            {loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ px: 1.5, py: 0.2 }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const DataTable = ({ data, removeByAbi }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Topic
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              ABI
            </Typography>
          </TableCell>
          <TableCell align="right" width={250}>
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <DataRow key={item._id} item={item} removeByAbi={removeByAbi} />
        ))}
      </TableBody>
    </Table>
  );
};

const ABIs = () => {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [topics, setTopics] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const searchTopics = async () => {
    setLoading(true);
    const body = {
      topic: searchTerm,
    };

    try {
      const res = await post(`abi/search/`, body);
      setTopics(res.data);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const removeByAbi = (abi) => {
    setTopics(topics.filter((topic) => topic.abi !== abi));
  };

  //part of pagination
  const loadData = async (page = 1) => {
    try {
      const res = await get(`/abis?page=${page}`);
      setTopics(res.data);
      setTotalPage(res.totalPages);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handlePageClick = (e) => {
    loadData(e.selected + 1);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            display: {
              xs: "inline",
              sm: "flex",
              lg: "flex",
            },
          }}
        >
          <Typography variant="h3">ABIs</Typography>
          <SearchBar
            searchTerm={searchTerm}
            handleChange={handleChange}
            searchFunction={searchTopics}
          />
          <Box>
            <Link to="/abis/add" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                Add
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            overflow: {
              xs: "auto",
              sm: "unset",
            },
          }}
        >
          {loading ? (
            <Box sx={{ textAlign: "center", p: 5 }}>
              <CircularProgress />
            </Box>
          ) : topics.length === 0 ? (
            <Typography variant="body1">No ABIs Found</Typography>
          ) : (
            <>
              <DataTable data={topics} removeByAbi={removeByAbi} />
              <Pagination
                totalPage={totalPage}
                handlePageClick={handlePageClick}
              />
            </>
          )}
        </Box>
      </CardContent>
      <ToastContainer />
    </Card>
  );
};

export default ABIs;
