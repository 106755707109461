import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
} from "@material-ui/core";
import { baseUrl, get, post, remove, globalUrl } from "../../api_service";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Pagination from "../../components/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteDialog from "../../components/DeleteDialog";
import SearchBar from "../../components/SearchBar";

import ToastOptions from "../../components/ToastOptions";

export const TC = ({ value }) => {
  return (
    <TableCell>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: "500",
        }}
      >
        {value}
      </Typography>
    </TableCell>
  );
};

const DataRow = ({ token, removeByAddress }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [bindOpen, setBindOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bindLoading, setBindLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [disabled, setDisabled] = useState(token.disabled);
  const baseUrl = "https://blockchain.thenineties.tech/img/";
  const [possibleMatches, setPossibleMatches] = useState([]);
  const [selectedForBind, setSelectedForBind] = useState({});
  const [bound, setBound] = useState(0);
  const [boundTo, setBoundTo] = useState({});
  const navigate = useNavigate();

  const getNetworkId = () => {
    const network = JSON.parse(localStorage.getItem("network"));
    return network.networkId;
  };

  const getPossibleMatches = async () => {
    try {
      const res = await post(
        `token/search`,
        {
          name: token.symbol,
        },
        "application/json",
        globalUrl
      );
      setPossibleMatches(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkIfBound = async () => {
      try {
        const res = await get(
          `token/selected/${token.address}/${getNetworkId()}`,
          globalUrl
        );
        if (res.data) {
          setBoundTo(res.data);
          setBound(1);
        } else {
          setBound(2);
          getPossibleMatches();
        }
      } catch (e) {
        console.log(e);
      }
    };
    checkIfBound();
  }, [token.address]);

  const addAsNew = async () => {
    setNewLoading(true);
    try {
      const data = {
        name: token.name,
        symbol: token.symbol,
        addresses: [
          {
            networkId: getNetworkId(),
            address: token.address,
          },
        ],
      };
      const res = await post(`token/add`, data, "application/json", globalUrl);
      if (res.status) {
        const res = await get(`token/${token.address}/enable`);
        if (res.status) {
          setDisabled(false);
        }
      }
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setNewLoading(false);
  };

  const deleteToken = async () => {
    try {
      setLoading(true);
      const res = await remove(`token/${token._id}/delete`);
      if (res.status) {
        removeByAddress(token.address);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.message, ToastOptions);
    }
  };

  const bindToken = async () => {
    setBindLoading(true);
    try {
      const res = await post(
        `token/${selectedForBind._id}/add-address`,
        {
          networkId: getNetworkId(),
          address: token.address,
        },
        "application/json",
        globalUrl
      );
      if (res.status) {
        const res = await get(`token/${token.address}/enable`);
        if (res.status) {
          setDisabled(false);
        }
      }
      toast.success("Binding Successfully", ToastOptions);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setBindLoading(false);
    setBindOpen(false);
  };

  return (
    <>
      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add as a new token?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are your sure you want to add this token as a new token to global
            tokens.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              addAsNew();
              setAddOpen(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bindOpen}
        onClose={() => setBindOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bind Token?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are your sure you want to bind {token.name} to selected token?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBindOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              bindToken();
            }}
            autoFocus
          >
            {bindLoading ? "Confirming ..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        onDelete={deleteToken}
      />
      <TableRow>
        <TableCell>
          <img
            src={`${baseUrl}${token.image}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://i.pinimg.com/originals/d5/99/b5/d599b52841025b15641a1ac54a2b25e7.png";
            }}
            alt={token.image}
            style={{ height: "30px", width: "30px" }}
          />
        </TableCell>
        <TC value={token.symbol} />
        <TC value={token.address} />
        <TC value={token.decimals} />
        <TC value={token.minlimit} />
        <TableCell>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "800",
              color: disabled ? "red" : "#39cb7f",
            }}
          >
            {disabled ? "Disabled" : "Active"}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {disabled && (
            <Button
              variant="contained"
              color="success"
              onClick={() => setAddOpen(true)}
              sx={{
                mr: 1,
                mb: 1,
              }}
            >
              {newLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ px: 1.5, py: 0.2 }}
                />
              ) : (
                "New"
              )}
            </Button>
          )}
          <Button
            disabled={token.address === "0x"}
            variant="contained"
            color="secondary"
            onClick={() => navigate("/tokens/edit", { state: token })}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            Edit
          </Button>
          <Button
            disabled={token.address === "0x"}
            variant="contained"
            color="error"
            onClick={() => setDeleteOpen(true)}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            {loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ px: 1.5, py: 0.2 }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} style={{ padding: 0 }}>
          {bound === 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p>Already Bound to</p>
              <h4>
                {boundTo.name} ({boundTo.symbol})
              </h4>
            </div>
          ) : (
            bound === 2 && (
              <>
                <h5 style={{ marginTop: "15px", marginBottom: "5px" }}>
                  Possible Matches
                </h5>
                {possibleMatches.length > 0 ? (
                  possibleMatches.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#eeeeee",
                          padding: "8px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <p>
                          {item.name} ({item.symbol})
                        </p>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setSelectedForBind(item);
                            setBindOpen(true);
                          }}
                        >
                          Bind
                        </Button>
                      </div>
                    );
                  })
                ) : (
                  <p>No Possible Matches found</p>
                )}
              </>
            )
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const DataTable = ({ data, removeByAddress }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Image
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Symbol
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Address
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Decimals
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Minimum Limit
            </Typography>
          </TableCell>

          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Status
            </Typography>
          </TableCell>
          <TableCell align="right" width={250}>
            <Typography color="textSecondary" variant="h6">
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((token) => (
          <DataRow
            token={token}
            removeByAddress={removeByAddress}
            key={token._id}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const Tokens = () => {
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [bulkUpload, setBulkUpload] = useState(false);
  const inputRef = useRef(null);
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchTokens = async () => {
    setLoading(true);
    const body = {
      name: searchTerm,
    };

    try {
      const res = await post(`token/search/`, body);
      setTokens(res.data);
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setLoading(false);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      await uploadFile(fileObj);
    }
  };

  const uploadFile = async (fileObj) => {
    setBulkUpload(true);
    try {
      const data = new FormData();
      data.append("file", fileObj);
      const res = await post("token/fromfile", data);
      if (res.status) {
        toast.success(res.message, ToastOptions);
        window.location.reload();
      }
    } catch (e) {
      toast.error(e.message, ToastOptions);
    }
    setBulkUpload(false);
  };

  const loadTokens = async (page = 1) => {
    try {
      const res = await get(`tokens/all?page=${page}`);
      setTokens(res.data);
      setTotalPage(res.totalPages);
      window.scrollTo(0, 0);
    } catch (e) {
    }
    setLoading(false);
  };

  const removeByAddress = (address) => {
    setTokens(tokens.filter((t) => t.address !== address));
  };

  const downloadTemplate = () => {
    window.location.href = `${baseUrl}assets/token_template.csv`;
  };

  const handlePageClick = (e) => {
    loadTokens(e.selected + 1);
  };

  useEffect(() => {
    loadTokens();
  }, []);

  return (
    <Box>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        multiple={false}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />

      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Available Tokens</Typography>
            <SearchBar
              searchTerm={searchTerm}
              handleChange={handleChange}
              searchFunction={searchTokens}
            />

            <Box>
              <Link to="/tokens/add" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Add
                </Button>
              </Link>
              <Button
                variant="contained"
                color="success"
                onClick={() => inputRef.current.click()}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                {bulkUpload ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ px: 1.5, py: 0.2 }}
                  />
                ) : (
                  "Bulk Upload"
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => downloadTemplate()}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
              >
                Template
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : tokens.length === 0 ? (
              <Typography variant="body1">No tokens found</Typography>
            ) : (
              <>
                <DataTable data={tokens} removeByAddress={removeByAddress} />
                <Pagination
                  handlePageClick={handlePageClick}
                  totalPage={totalPage}
                />
              </>
            )}
          </Box>
        </CardContent>
        <ToastContainer />
      </Card>
    </Box>
  );
};

export default Tokens;
