import {
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Autocomplete,
} from "@material-ui/core";
import React, { useState } from "react";

const fromTypes = ["transaction", "inputs", "logs", "tokensTransfered"];
const types = [
  "address",
  "tokenAddress",
  "iterable",
  "bigNumber",
  "other",
  "txHash",
  "blockNumber",
];
const transactionInputs = ["from", "to", "hash", "blockNumber"];
const tokenTransferedInputs = ["first", "last"];

const RouterParamEdit = ({
  param,
  index,
  params,
  setParams,
  inputs,
  logInputs,
}) => {
  const [selectedArg, setSelectedArg] = useState(param.name || []);
  const [selectedType, setSelectedType] = useState(param.type || types[0]);
  const [selectedFinalType, setSelectedFinalType] = useState(
    param.finalType || types[0]
  );
  const [saved, setSaved] = useState(0);
  const [showError, setShowError] = useState(false);
  const [fromType, setFromType] = useState(
    param.from === "" ? fromTypes[0] : param.from
  );
  const propName = param.prop;
  const [pIndex, setPIndex] = useState(param.index?.toString() || "0");

  const onFromTypeChange = (type) => {
    setFromType(type);
  };

  const onIndexChange = (e) => {
    setSaved(1);
    let input = e.target.value;
    if (input) {
      if (input.match("[0-9]")) {
        setPIndex(input);
      }
    } else {
      setPIndex("");
    }
  };

  const validate = () => {
    if (selectedType === "iterable") {
      return propName !== "" && pIndex !== "";
    }
    return propName !== "";
  };

  const save = () => {
    setShowError(true);
    if (validate()) {
      const newParams = params.slice();
      const param = {
        prop: propName,
        name: selectedArg,
        type: selectedType,
        from: fromType,
        index: pIndex,
        finalType: selectedFinalType,
      };
      newParams[index] = param;
      setParams(newParams);
      setSaved(2);
    }
  };

  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: 1,
        paddingBottom: 0.5,
        borderBottom:
          saved === 2
            ? "4px solid #39cb7f"
            : saved === 1
            ? "4px solid red"
            : "",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ marginRight: 1 }}>
          <Typography>Param</Typography>
          <TextField
            value={propName}
            sx={{ width: "100px" }}
            disabled
            size="small"
            error={showError && propName === ""}
            helperText={showError && propName === "" ? "Required" : ""}
          />
        </Box>
        <Box sx={{ marginRight: 1 }}>
          <Typography>From</Typography>
          <TextField
            select
            sx={{ width: "150px" }}
            value={fromType}
            onChange={(e) => {
              setSaved(1);
              onFromTypeChange(e.target.value);
            }}
            size="small"
            error={showError && fromType === ""}
            helperText={showError && fromType === "" ? "Required" : ""}
          >
            {fromTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box sx={{ marginRight: 1 }}>
          <Typography>Arg</Typography>
          <Autocomplete
            multiple
            options={
              fromType === "transaction"
                ? transactionInputs
                : fromType === "inputs"
                ? inputs.map((e) => e.name)
                : fromType === "tokensTransfered"
                ? tokenTransferedInputs
                : logInputs
            }
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              setSaved(1);
              setSelectedArg(value);
            }}
            defaultValue={selectedArg}
            value={selectedArg}
            renderInput={(params) => (
              <TextField
                {...params}
                value={selectedArg}
                sx={{ minWidth: "150px" }}
                size="small"
              />
            )}
          />
        </Box>

        <Box sx={{ marginRight: 1 }}>
          <Typography>Type</Typography>
          <TextField
            select
            sx={{ width: "150px" }}
            size="small"
            value={selectedType}
            onChange={(e) => {
              setSaved(1);
              setSelectedType(e.target.value);
            }}
          >
            {types.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {selectedType === "iterable" && (
          <Box sx={{ marginRight: 1 }}>
            <Typography>Index</Typography>
            <TextField
              value={pIndex}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              onChange={onIndexChange}
              size="small"
              sx={{ width: "150px" }}
              error={showError && pIndex === ""}
              helperText={showError && pIndex === "" ? "Required" : ""}
            />
          </Box>
        )}

        {selectedType === "iterable" && (
          <Box sx={{ marginRight: 1 }}>
            <Typography>Final Type</Typography>
            <TextField
              select
              sx={{ width: "150px" }}
              size="small"
              value={selectedFinalType}
              onChange={(e) => {
                setSaved(1);
                setSelectedFinalType(e.target.value);
              }}
            >
              {types.map(
                (option, index) =>
                  option !== "iterable" && (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  )
              )}
            </TextField>
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="contained"
          color="success"
          onClick={save}
          sx={{ marginRight: 1 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default RouterParamEdit;
