import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post } from "../../api_service";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";
const msgError =
  "Message must include [from], [function], [amountin], [tokenin], [amount], [tokenout], [txhash], [blockno]";
const keys = [
  "[from]",
  "[function]",
  "[amountin]",
  "[tokenin]",
  "[amount]",
  "[tokenout]",
  "[txhash]",
  "[blockno]",
];

const AddRouterGroup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    type: "",
    message: "",
  });
  const [showError, setShowError] = useState(false);
  const isValid = () => {
    return data.type !== "" && isMessageValid(data.message);
  };

  const isMessageValid = (message) => {
    let isValid = true;
    keys.forEach((key) => {
      isValid = message.includes(key);
    });
    return isValid;
  };

  const add = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        const res = await post("router-group/add", data);
        if (res.status) {
          setLoading(false);
          navigate(-1);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add Dex Function</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                id="default-value"
                label="Type"
                variant="outlined"
                error={showError && data.type === ""}
                onChange={(e) => setData({ ...data, type: e.target.value })}
                helperText={showError && data.type === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              />
              <Typography variant="caption" color="primary">
                [from]: From Address, [function]: Name of the function,
                [router]: Name of the router, [tokenin]: Name of the token in,
                [amount]: Total value, [tokenout]: Name of the token out,
                [txhash]: Transactions Hash, [blockno]: Block Number
              </Typography>
              <TextField
                id="default-value"
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                error={showError && !isMessageValid(data.message)}
                onChange={(e) => setData({ ...data, message: e.target.value })}
                helperText={
                  showError && !isMessageValid(data.message) ? msgError : ""
                }
                sx={{
                  mt: 0.7,
                }}
              />

              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => add()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default AddRouterGroup;
