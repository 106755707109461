import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { get } from "../../api_service";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const WhaleDetail = () => {
  const location = useLocation();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const baseUrl = "https://blockchain.thenineties.tech/img/";

  useEffect(() => {
    const loadDetail = async () => {
      setLoading(true);
      try {
        const res = await get(`whales/${location.state.address}/details`);
        setDetails(res.data);
      } catch (e) {
        console.log(e);
        toast.error(e.message, ToastOptions);
      }
      setLoading(false);
    };
    loadDetail();
  }, [location.state.address]);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              justifyContent: "space-between",
              display: {
                xs: "inline",
                sm: "flex",
                lg: "flex",
              },
            }}
          >
            <Typography variant="h3">Whale Detail</Typography>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "#1a97f5" }}
              href={`https://etherscan.io/address/${location.state.address}`}
            >
              <Typography variant="h4">{location.state.address}</Typography>
            </a>
          </Box>
          <Box
            sx={{
              mt: 2,
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loading ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : details.length === 0 ? (
              <Box sx={{ textAlign: "center", p: 5 }}>
                <Typography variant="body1">No tokens found</Typography>
              </Box>
            ) : (
              details.map((token) => {
                return (
                  <Box
                    sx={{
                      borderRadius: 4,
                      backgroundColor: "#eeeeee",
                      px: 2,
                      py: 1,
                      my: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#1a97f5" }}
                      href={`https://etherscan.io/address/${token.address}`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={`${baseUrl}${token.image}`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://i.pinimg.com/originals/d5/99/b5/d599b52841025b15641a1ac54a2b25e7.png";
                          }}
                          alt={token.image}
                          style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                          }}
                        />
                        <Typography>
                          {token.name} ({token.symbol})
                        </Typography>
                      </Box>
                    </a>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "end",
                        backgroundColor: "#1a97f5",
                        borderRadius: 4,
                        py: 0.5,
                        px: 2,
                      }}
                    >
                      <Typography
                        color="white"
                        variant="h4"
                        style={{ marginRight: "3px" }}
                      >
                        {token.count}{" "}
                      </Typography>
                      <Typography color="white" variant="h6">
                        Subs
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default WhaleDetail;
