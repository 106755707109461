import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DeleteDialog = ({ onDelete, deleteOpen, setDeleteOpen }) => {
  return (
    <Dialog
      open={deleteOpen}
      onClose={() => setDeleteOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete this item?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are your sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
        <Button
          onClick={() => {
            onDelete();
            setDeleteOpen(false);
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
