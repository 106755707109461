import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { post, globalUrl, get } from "../../api_service";
import { ethers } from "ethers";
import { useLocation, useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import ToastOptions from "../../components/ToastOptions";

const errorTxt = "This field is required";
const baseUrl = "https://blockchain.thenineties.tech/img/";

const AddToken = () => {
  const location = useLocation();
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: location.state.name,
    symbol: location.state.symbol,
    decimals: location.state.decimals,
    address: location.state.address,
    minlimit: location.state.minlimit,
  });
  const [showError, setShowError] = useState(false);
  const [globalTokens, setGlobalTokens] = useState([
    { _id: 0, name: "Add as new token" },
  ]);
  const [selectedGlobal, setSelectedGlobal] = useState(null);

  useEffect(() => {
    const loadSelectedGlobal = async () => {
      setLoading(true);
      try {
        const res = await get(
          `token/selected/${data.address}/${getNetworkId()}`,
          globalUrl
        );
        if (res.data) {
          let global = globalTokens;
          global.push(res.data);
          setGlobalTokens(global);
          setSelectedGlobal(res.data);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message, ToastOptions);
      }
      setLoading(false);
    };

    loadSelectedGlobal();
  }, []);

  const getNetworkId = () => {
    const network = JSON.parse(localStorage.getItem("network"));
    return network.networkId;
  };

  const loadGlobal = async (name) => {
    try {
      const res = await post(
        `token/search`,
        {
          name: name,
        },
        "application/json",
        globalUrl
      );
      setGlobalTokens([{ _id: 0, name: "Add as new token" }, ...res.data]);
    } catch (e) {
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  const isValid = () => {
    let valid = true;
    for (const key in data) {
      if (key === "address") {
        valid = ethers.utils.isAddress(data[key]);
      } else {
        valid = data[key] !== "";
      }
    }
    return valid && selectedGlobal;
  };

  const update = async () => {
    try {
      setShowError(true);
      if (isValid()) {
        setLoading(true);
        if (image) {
          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("symbol", data.symbol);
          formData.append("address", data.address);
          formData.append("decimals", data.decimals);
          formData.append("image", image);
          const res = await post(
            `token/${location.state._id}/update`,
            formData
          );
          if (res.status) {
            await updateOnGlobal();
          }
        } else {
          const res = await post(`token/${location.state._id}/update`, data);
          if (res.status) {
            await updateOnGlobal();
          }
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error(e.message, ToastOptions);
    }
  };

  const updateOnGlobal = async () => {
    if (selectedGlobal._id === 0) {
      const res = await post(
        `token/add`,
        {
          name: data.name,
          symbol: data.symbol,
          addresses: [
            {
              networkId: getNetworkId(),
              address: data.address,
            },
          ],
        },
        "application/json",
        globalUrl
      );
      if (res.status) {
        navigate(-1);
      }
    } else {
      const res = await post(
        `token/${data.address}/update-address`,
        {
          networkId: getNetworkId(),
          id: selectedGlobal._id,
        },
        "application/json",
        globalUrl
      );
      if (res.status) {
        navigate(-1);
      }
    }
  };

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Add Token</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <form>
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.address}
                label="Address"
                variant="outlined"
                error={showError && !ethers.utils.isAddress(data.address)}
                helperText={
                  showError && !ethers.utils.isAddress(data.address)
                    ? "Address is not valid"
                    : ""
                }
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.name}
                label="Name"
                variant="outlined"
                fullWidth
                error={showError && data.name === ""}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                helperText={showError && data.name === "" ? errorTxt : ""}
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.symbol}
                label="Symbol"
                variant="outlined"
                error={showError && data.symbol === ""}
                helperText={showError && data.symbol === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.decimals}
                label="Decimals"
                variant="outlined"
                type="number"
                error={showError && data.decimals === ""}
                helperText={showError && data.decimals === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />

              <TextField
                inputprops={{
                  readOnly: true,
                }}
                value={data.minlimit}
                label="Minimum Limit"
                variant="outlined"
                type="number"
                onChange={(e) => setData({ ...data, minlimit: e.target.value })}
                error={showError && data.minlimit === ""}
                helperText={showError && data.minlimit === "" ? errorTxt : ""}
                fullWidth
                sx={{
                  mt: 2,
                }}
              />

              <Autocomplete
                disablePortal
                fullWidth
                value={selectedGlobal}
                onChange={(event, value) => {
                  setSelectedGlobal(value);
                }}
                onInputChange={(event, value) => {
                  loadGlobal(value);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                options={globalTokens}
                renderInput={(params) => (
                  <TextField
                    disabled
                    error={showError && !selectedGlobal}
                    helperText={showError && !selectedGlobal ? errorTxt : ""}
                    {...params}
                    label="Global Token"
                  />
                )}
                sx={{
                  mt: 2,
                }}
              />

              <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <input
                  type="file"
                  multiple={false}
                  accept="image/*"
                  onChange={onImageChange}
                />
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    alt={""}
                    style={{ height: "50px" }}
                  />
                ) : (
                  <img
                    src={`${baseUrl}${location.state.image}`}
                    alt=""
                    style={{ height: "50px" }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  mt: 2,
                  textAlign: "right",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  onClick={() => update()}
                >
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={25}
                      sx={{ px: 2 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default AddToken;
